<template>
  <div id='misc'>
    <div class='page-title text-center px-5'>
      <h2 class='text-2xl font-weight-semibold text--primary d-flex align-center justify-center'>
        <span class='me-2'>UnAuthorize</span>
        <v-icon color='warning'>
          {{ icons.mdiAlert }}
        </v-icon>
      </h2>
      <p class='text-sm'>
        401
      </p>

      <div class='misc-character d-flex justify-center'>
        <img
          src='@/assets/images/3d-characters/error.png'
          style='height: 50vh'
        ></img>
      </div>

      <v-btn
        class='mb-4'
        color='primary'
        to='/projects'
      >
        Back to home
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang='scss'>
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
